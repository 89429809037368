import React, { useState, useEffect } from 'react';
import {
  NotificationOutlined,
  InfoCircleOutlined,
  WarningOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

// Global reference to add notifications from anywhere in the app
let addNotificationGlobal;

const NotificationBox = () => {
  const [notifications, setNotifications] = useState([]);

  // Local add notification function
  const addNotification = (name, level, description) => {
    setNotifications((prev) => {
      // Check if the notification already exists
      const exists = prev.some(
        (notification) => notification.component_name === name && notification.status === level && notification.description === description,
      );
      if (exists) {
        return prev; // Return the current list unchanged if it already exists
      }
      return [{ name, level, description }, ...prev.slice(0, 4)]; // Add new notification if unique
    });
  };

  // Expose global function for external use
  useEffect(() => {
    addNotificationGlobal = addNotification;
    return () => {
      addNotificationGlobal = null; // Cleanup on unmount
    };
  }, []);

  // Example: Fetch Kafka data and display notifications
const fetchKafkaData = async () => {
  try {
    const response = await fetch(`${window.__RUNTIME_CONFIG__.REACT_APP_API_ROOT_URI}/Kafka/monitoring`);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const data = await response.json();
    if (data?.component_name && data?.status && data?.description) {
      addNotification(data.component_name, data.status, data.description);
    }
  } catch (error) {
    addNotification('Monitor Notifications', 'CRITICAL', 'Failed to fetch Kafka data.');
    console.log(error);
  } finally {
    setTimeout(fetchKafkaData, 1000);
  }
};

useEffect(() => {
  fetchKafkaData();
  return () => {};
}, []);

  const notificationIcon = (level) => {
    switch (level) {
      case 'CRITICAL':
        return <CloseCircleOutlined />;
      case 'WARNING':
        return <WarningOutlined />;
      case 'SUCCESS':
        return <CheckCircleOutlined />;
      case 'INFO':
      default:
        return <InfoCircleOutlined />;
    }
  };

  const getNotificationBackgroundColor = (level) => {
    switch (level) {
      case 'CRITICAL':
        return '#ffccc7';
      case 'WARNING':
        return '#fff4e5';
      case 'SUCCESS':
        return '#d9f7be';
      case 'INFO':
      default:
        return '#e6f7ff';
    }
  };

  return (
    <div
      style={{
        margin: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        backgroundColor: '#5BC0E1',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          padding: '10px 0',
          backgroundColor: '#007bb5',
          color: '#fff',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        }}
      >
        <h3 style={{ margin: 0 }}>
          <NotificationOutlined /> Notifications
        </h3>
      </div>
      <div
        style={{
          maxHeight: '290px',
          overflowY: 'auto',
          padding: '5px',
          scrollbarWidth: 'thin',
          scrollbarColor: '#007bb5 #5BC0E1',
        }}
      >
        <style>
          {`
            div::-webkit-scrollbar {
              width: 10px;
            }
            div::-webkit-scrollbar-track {
              background: #5BC0E1;
              border-radius: 10px;
            }
            div::-webkit-scrollbar-thumb {
              background: #007bb5;
              border-radius: 10px;
            }
            div::-webkit-scrollbar-thumb:hover {
              background: #005f8d;
            }
          `}
        </style>
        {notifications.length > 0 ? (
          notifications.map((notification, index) => (
            <div
              key={index}
              style={{
                backgroundColor: getNotificationBackgroundColor(notification.level),
                color: '#333',
                padding: '10px',
                margin: '5px 10px',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <div>
                <p style={{ margin: 0 }}>
                  {notificationIcon(notification.level)}
                  <strong style={{ marginLeft: '5px' }}>{notification.name}</strong>
                </p>
                <p style={{ margin: 0 }}>{notification.description}</p>
              </div>
            </div>
          ))
        ) : (
          <div
            style={{
              color: '#333',
              padding: '10px',
              margin: '5px 10px',
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              textAlign: 'center',
              backgroundColor: getNotificationBackgroundColor(''),
            }}
          >
            No Notifications Yet!
          </div>
        )}
      </div>
    </div>
  );
};

// Export global addNotification function
export const addAppCustomNotification = (name, level, description) => {
  if (addNotificationGlobal) {
    addNotificationGlobal(name, level, description);
  } else {
    console.warn('Notification manager is not initialized.');
  }
};

export default NotificationBox;
