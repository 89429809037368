import React, { useState, useEffect } from 'react';
import MainLayout from '../layouts/MainLayout';
import WikiComponent from '../components/WikiComponent';
import { Form, Select, Button, Checkbox, Collapse, message, Cascader, TreeSelect, Card, Col } from 'antd';
import { color } from 'd3';

const { Option } = Select;
const { Panel } = Collapse;
export default function WikiView() {
  const data = [
    {
      value: '1',
      label: 'Product 1',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'first_guess',
              label: 'first_guess',
            },
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'daily_mean',
              label: 'daily_mean',
            },
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'latest',
              label: 'latest',
            },
            {
              value: 'v20r1',
              label: 'v20r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '2',
      label: 'Product 2',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'first_guess',
              label: 'first_guess',
            },
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'daily_mean',
              label: 'daily_mean',
            },
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v18r1',
              label: 'v18r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
          ],
        },
      ],
    },
    {
      value: '3',
      label: 'Product 3',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'first_guess',
              label: 'first_guess',
            },
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'daily_mean',
              label: 'daily_mean',
            },
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v19r1',
              label: 'v19r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
          ],
        },
      ],
    },
    {
      value: '4',
      label: 'Product 4',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'first_guess',
              label: 'first_guess',
            },
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v17r1',
              label: 'v17r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
          ],
        },
      ],
    },
    {
      value: '5',
      label: 'Product 5',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'first_guess',
              label: 'first_guess',
            },
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'latest',
              label: 'latest',
            },
            {
              value: 'v20r1',
              label: 'v20r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '6',
      label: 'Product 6',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'first_guess',
              label: 'first_guess',
            },
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v18r1',
              label: 'v18r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
          ],
        },
      ],
    },
    {
      value: '7',
      label: 'Product 7',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'first_guess',
              label: 'first_guess',
            },
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v19r1',
              label: 'v19r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
          ],
        },
      ],
    },
    {
      value: '8',
      label: 'Product 8',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'first_guess',
              label: 'first_guess',
            },
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'latest',
              label: 'latest',
            },
            {
              value: 'v20r1',
              label: 'v20r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '9',
      label: 'Product 9',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'first_guess',
              label: 'first_guess',
            },
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v17r1',
              label: 'v17r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
          ],
        },
      ],
    },
    {
      value: '10',
      label: 'Product 10',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'first_guess',
              label: 'first_guess',
            },
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v18r1',
              label: 'v18r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
          ],
        },
      ],
    },
    {
      value: '11',
      label: 'Product 11',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'first_guess',
              label: 'first_guess',
            },
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v19r1',
              label: 'v19r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
          ],
        },
      ],
    },
    {
      value: '12',
      label: 'Product 12',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'first_guess',
              label: 'first_guess',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v17r1',
              label: 'v17r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
          ],
        },
      ],
    },
    {
      value: '13',
      label: 'Product 13',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'first_guess',
              label: 'first_guess',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v17r1',
              label: 'v17r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
          ],
        },
      ],
    },
    {
      value: '14',
      label: 'Product 14',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v18r1',
              label: 'v18r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
          ],
        },
      ],
    },
    {
      value: '15',
      label: 'Product 15',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v19r1',
              label: 'v19r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
          ],
        },
      ],
    },
    {
      value: '16',
      label: 'Product 16',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v18r1',
              label: 'v18r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
          ],
        },
      ],
    },
    {
      value: '17',
      label: 'Product 17',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v19r1',
              label: 'v19r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
          ],
        },
      ],
    },
    {
      value: '18',
      label: 'Product 18',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v20r2',
              label: 'v20r2',
            },
            {
              value: 'v20r3',
              label: 'v20r3',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '19',
      label: 'Product 19',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v21r1',
              label: 'v21r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
            {
              value: '2021',
              label: '2021',
            },
          ],
        },
      ],
    },
    {
      value: '20',
      label: 'Product 20',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v20r2',
              label: 'v20r2',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '21',
      label: 'Product 21',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v20r3',
              label: 'v20r3',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '22',
      label: 'Product 22',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v21r1',
              label: 'v21r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
            {
              value: '2021',
              label: '2021',
            },
          ],
        },
      ],
    },
    {
      value: '23',
      label: 'Product 23',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'latest',
              label: 'latest',
            },
            {
              value: 'v21r2',
              label: 'v21r2',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
            {
              value: '2021',
              label: '2021',
            },
          ],
        },
      ],
    },
    {
      value: '24',
      label: 'Product 24',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v20r1',
              label: 'v20r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '25',
      label: 'Product 25',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'latest',
              label: 'latest',
            },
            {
              value: 'v21r2',
              label: 'v21r2',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
            {
              value: '2021',
              label: '2021',
            },
          ],
        },
      ],
    },
    {
      value: '26',
      label: 'Product 26',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v20r1',
              label: 'v20r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '27',
      label: 'Product 27',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'latest',
              label: 'latest',
            },
            {
              value: 'v21r2',
              label: 'v21r2',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
            {
              value: '2021',
              label: '2021',
            },
          ],
        },
      ],
    },
    {
      value: '28',
      label: 'Product 28',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v20r1',
              label: 'v20r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
          ],
        },
      ],
    },
    {
      value: '29',
      label: 'Product 29',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'latest',
              label: 'latest',
            },
            {
              value: 'v21r2',
              label: 'v21r2',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
            {
              value: '2021',
              label: '2021',
            },
          ],
        },
      ],
    },
    {
      value: '30',
      label: 'Product 30',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v20r1',
              label: 'v20r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
          ],
        },
      ],
    },
    {
      value: '31',
      label: 'Product 31',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v18r1',
              label: 'v18r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
          ],
        },
      ],
    },
    {
      value: '32',
      label: 'Product 32',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v19r1',
              label: 'v19r1',
            },
            {
              value: 'v20r2',
              label: 'v20r2',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
          ],
        },
      ],
    },
    {
      value: '33',
      label: 'Product 33',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v20r3',
              label: 'v20r3',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '34',
      label: 'Product 34',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v20r2',
              label: 'v20r2',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
          ],
        },
      ],
    },
    {
      value: '35',
      label: 'Product 35',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v20r3',
              label: 'v20r3',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '36',
      label: 'Product 36',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v21r1',
              label: 'v21r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
            {
              value: '2021',
              label: '2021',
            },
          ],
        },
      ],
    },
    {
      value: '37',
      label: 'Product 37',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v21r1',
              label: 'v21r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
            {
              value: '2021',
              label: '2021',
            },
          ],
        },
      ],
    },
    {
      value: '38',
      label: 'Product 38',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v21r1',
              label: 'v21r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '39',
      label: 'Product 39',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'satellite',
              label: 'satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v21r1',
              label: 'v21r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '40',
      label: 'Product 40',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'latest',
              label: 'latest',
            },
            {
              value: 'v21r1',
              label: 'v21r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1979',
              label: '1979',
            },
            {
              value: '1980',
              label: '1980',
            },
            {
              value: '1981',
              label: '1981',
            },
            {
              value: '1982',
              label: '1982',
            },
            {
              value: '1983',
              label: '1983',
            },
            {
              value: '1984',
              label: '1984',
            },
            {
              value: '1985',
              label: '1985',
            },
            {
              value: '1986',
              label: '1986',
            },
            {
              value: '1987',
              label: '1987',
            },
            {
              value: '1988',
              label: '1988',
            },
            {
              value: '1989',
              label: '1989',
            },
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
            {
              value: '2021',
              label: '2021',
            },
          ],
        },
      ],
    },
    {
      value: '41',
      label: 'Product 41',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v17r1',
              label: 'v17r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1979',
              label: '1979',
            },
            {
              value: '1980',
              label: '1980',
            },
            {
              value: '1981',
              label: '1981',
            },
            {
              value: '1982',
              label: '1982',
            },
            {
              value: '1983',
              label: '1983',
            },
            {
              value: '1984',
              label: '1984',
            },
            {
              value: '1985',
              label: '1985',
            },
            {
              value: '1986',
              label: '1986',
            },
            {
              value: '1987',
              label: '1987',
            },
            {
              value: '1988',
              label: '1988',
            },
            {
              value: '1989',
              label: '1989',
            },
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
          ],
        },
      ],
    },
    {
      value: '42',
      label: 'Product 42',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v18r2',
              label: 'v18r2',
            },
            {
              value: 'v18r3',
              label: 'v18r3',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1979',
              label: '1979',
            },
            {
              value: '1980',
              label: '1980',
            },
            {
              value: '1981',
              label: '1981',
            },
            {
              value: '1982',
              label: '1982',
            },
            {
              value: '1983',
              label: '1983',
            },
            {
              value: '1984',
              label: '1984',
            },
            {
              value: '1985',
              label: '1985',
            },
            {
              value: '1986',
              label: '1986',
            },
            {
              value: '1987',
              label: '1987',
            },
            {
              value: '1988',
              label: '1988',
            },
            {
              value: '1989',
              label: '1989',
            },
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
          ],
        },
      ],
    },
    {
      value: '43',
      label: 'Product 43',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v19r1',
              label: 'v19r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1979',
              label: '1979',
            },
            {
              value: '1980',
              label: '1980',
            },
            {
              value: '1981',
              label: '1981',
            },
            {
              value: '1982',
              label: '1982',
            },
            {
              value: '1983',
              label: '1983',
            },
            {
              value: '1984',
              label: '1984',
            },
            {
              value: '1985',
              label: '1985',
            },
            {
              value: '1986',
              label: '1986',
            },
            {
              value: '1987',
              label: '1987',
            },
            {
              value: '1988',
              label: '1988',
            },
            {
              value: '1989',
              label: '1989',
            },
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
          ],
        },
      ],
    },
    {
      value: '44',
      label: 'Product 44',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v20r2',
              label: 'v20r2',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1979',
              label: '1979',
            },
            {
              value: '1980',
              label: '1980',
            },
            {
              value: '1981',
              label: '1981',
            },
            {
              value: '1982',
              label: '1982',
            },
            {
              value: '1983',
              label: '1983',
            },
            {
              value: '1984',
              label: '1984',
            },
            {
              value: '1985',
              label: '1985',
            },
            {
              value: '1986',
              label: '1986',
            },
            {
              value: '1987',
              label: '1987',
            },
            {
              value: '1988',
              label: '1988',
            },
            {
              value: '1989',
              label: '1989',
            },
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '45',
      label: 'Product 45',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'nitrous_oxide',
              label: 'nitrous_oxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'latest',
              label: 'latest',
            },
            {
              value: 'v20r1',
              label: 'v20r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '46',
      label: 'Product 46',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'nitrous_oxide',
              label: 'nitrous_oxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v16r1',
              label: 'v16r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
          ],
        },
      ],
    },
    {
      value: '47',
      label: 'Product 47',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'nitrous_oxide',
              label: 'nitrous_oxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v17r1',
              label: 'v17r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
          ],
        },
      ],
    },
    {
      value: '48',
      label: 'Product 48',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'nitrous_oxide',
              label: 'nitrous_oxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v18r1',
              label: 'v18r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
          ],
        },
      ],
    },
    {
      value: '49',
      label: 'Product 49',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'nitrous_oxide',
              label: 'nitrous_oxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v19r1',
              label: 'v19r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
          ],
        },
      ],
    },
    {
      value: '50',
      label: 'Product 50',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v17r1',
              label: 'v17r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
          ],
        },
      ],
    },
    {
      value: '51',
      label: 'Product 51',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'latest',
              label: 'latest',
            },
            {
              value: 'v21r1',
              label: 'v21r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1979',
              label: '1979',
            },
            {
              value: '1980',
              label: '1980',
            },
            {
              value: '1981',
              label: '1981',
            },
            {
              value: '1982',
              label: '1982',
            },
            {
              value: '1983',
              label: '1983',
            },
            {
              value: '1984',
              label: '1984',
            },
            {
              value: '1985',
              label: '1985',
            },
            {
              value: '1986',
              label: '1986',
            },
            {
              value: '1987',
              label: '1987',
            },
            {
              value: '1988',
              label: '1988',
            },
            {
              value: '1989',
              label: '1989',
            },
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
            {
              value: '2021',
              label: '2021',
            },
          ],
        },
      ],
    },
    {
      value: '52',
      label: 'Product 52',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v17r1',
              label: 'v17r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1979',
              label: '1979',
            },
            {
              value: '1980',
              label: '1980',
            },
            {
              value: '1981',
              label: '1981',
            },
            {
              value: '1982',
              label: '1982',
            },
            {
              value: '1983',
              label: '1983',
            },
            {
              value: '1984',
              label: '1984',
            },
            {
              value: '1985',
              label: '1985',
            },
            {
              value: '1986',
              label: '1986',
            },
            {
              value: '1987',
              label: '1987',
            },
            {
              value: '1988',
              label: '1988',
            },
            {
              value: '1989',
              label: '1989',
            },
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
          ],
        },
      ],
    },
    {
      value: '53',
      label: 'Product 53',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v18r2',
              label: 'v18r2',
            },
            {
              value: 'v18r3',
              label: 'v18r3',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1979',
              label: '1979',
            },
            {
              value: '1980',
              label: '1980',
            },
            {
              value: '1981',
              label: '1981',
            },
            {
              value: '1982',
              label: '1982',
            },
            {
              value: '1983',
              label: '1983',
            },
            {
              value: '1984',
              label: '1984',
            },
            {
              value: '1985',
              label: '1985',
            },
            {
              value: '1986',
              label: '1986',
            },
            {
              value: '1987',
              label: '1987',
            },
            {
              value: '1988',
              label: '1988',
            },
            {
              value: '1989',
              label: '1989',
            },
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
          ],
        },
      ],
    },
    {
      value: '54',
      label: 'Product 54',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v19r1',
              label: 'v19r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1979',
              label: '1979',
            },
            {
              value: '1980',
              label: '1980',
            },
            {
              value: '1981',
              label: '1981',
            },
            {
              value: '1982',
              label: '1982',
            },
            {
              value: '1983',
              label: '1983',
            },
            {
              value: '1984',
              label: '1984',
            },
            {
              value: '1985',
              label: '1985',
            },
            {
              value: '1986',
              label: '1986',
            },
            {
              value: '1987',
              label: '1987',
            },
            {
              value: '1988',
              label: '1988',
            },
            {
              value: '1989',
              label: '1989',
            },
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
          ],
        },
      ],
    },
    {
      value: '55',
      label: 'Product 55',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v20r2',
              label: 'v20r2',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1979',
              label: '1979',
            },
            {
              value: '1980',
              label: '1980',
            },
            {
              value: '1981',
              label: '1981',
            },
            {
              value: '1982',
              label: '1982',
            },
            {
              value: '1983',
              label: '1983',
            },
            {
              value: '1984',
              label: '1984',
            },
            {
              value: '1985',
              label: '1985',
            },
            {
              value: '1986',
              label: '1986',
            },
            {
              value: '1987',
              label: '1987',
            },
            {
              value: '1988',
              label: '1988',
            },
            {
              value: '1989',
              label: '1989',
            },
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '56',
      label: 'Product 56',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'nitrous_oxide',
              label: 'nitrous_oxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'latest',
              label: 'latest',
            },
            {
              value: 'v20r1',
              label: 'v20r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '57',
      label: 'Product 57',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'nitrous_oxide',
              label: 'nitrous_oxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v16r1',
              label: 'v16r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
          ],
        },
      ],
    },
    {
      value: '58',
      label: 'Product 58',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'nitrous_oxide',
              label: 'nitrous_oxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v17r1',
              label: 'v17r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
          ],
        },
      ],
    },
    {
      value: '59',
      label: 'Product 59',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'nitrous_oxide',
              label: 'nitrous_oxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v18r1',
              label: 'v18r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
          ],
        },
      ],
    },
    {
      value: '60',
      label: 'Product 60',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'nitrous_oxide',
              label: 'nitrous_oxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v19r1',
              label: 'v19r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
          ],
        },
      ],
    },
    {
      value: '61',
      label: 'Product 61',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v18r1',
              label: 'v18r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1979',
              label: '1979',
            },
            {
              value: '1980',
              label: '1980',
            },
            {
              value: '1981',
              label: '1981',
            },
            {
              value: '1982',
              label: '1982',
            },
            {
              value: '1983',
              label: '1983',
            },
            {
              value: '1984',
              label: '1984',
            },
            {
              value: '1985',
              label: '1985',
            },
            {
              value: '1986',
              label: '1986',
            },
            {
              value: '1987',
              label: '1987',
            },
            {
              value: '1988',
              label: '1988',
            },
            {
              value: '1989',
              label: '1989',
            },
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
          ],
        },
      ],
    },
    {
      value: '62',
      label: 'Product 62',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v18r1',
              label: 'v18r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1979',
              label: '1979',
            },
            {
              value: '1980',
              label: '1980',
            },
            {
              value: '1981',
              label: '1981',
            },
            {
              value: '1982',
              label: '1982',
            },
            {
              value: '1983',
              label: '1983',
            },
            {
              value: '1984',
              label: '1984',
            },
            {
              value: '1985',
              label: '1985',
            },
            {
              value: '1986',
              label: '1986',
            },
            {
              value: '1987',
              label: '1987',
            },
            {
              value: '1988',
              label: '1988',
            },
            {
              value: '1989',
              label: '1989',
            },
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
          ],
        },
      ],
    },
    {
      value: '63',
      label: 'Product 63',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v20r1',
              label: 'v20r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1979',
              label: '1979',
            },
            {
              value: '1980',
              label: '1980',
            },
            {
              value: '1981',
              label: '1981',
            },
            {
              value: '1982',
              label: '1982',
            },
            {
              value: '1983',
              label: '1983',
            },
            {
              value: '1984',
              label: '1984',
            },
            {
              value: '1985',
              label: '1985',
            },
            {
              value: '1986',
              label: '1986',
            },
            {
              value: '1987',
              label: '1987',
            },
            {
              value: '1988',
              label: '1988',
            },
            {
              value: '1989',
              label: '1989',
            },
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '64',
      label: 'Product 64',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v20r1',
              label: 'v20r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1979',
              label: '1979',
            },
            {
              value: '1980',
              label: '1980',
            },
            {
              value: '1981',
              label: '1981',
            },
            {
              value: '1982',
              label: '1982',
            },
            {
              value: '1983',
              label: '1983',
            },
            {
              value: '1984',
              label: '1984',
            },
            {
              value: '1985',
              label: '1985',
            },
            {
              value: '1986',
              label: '1986',
            },
            {
              value: '1987',
              label: '1987',
            },
            {
              value: '1988',
              label: '1988',
            },
            {
              value: '1989',
              label: '1989',
            },
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '65',
      label: 'Product 65',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v20r1',
              label: 'v20r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1979',
              label: '1979',
            },
            {
              value: '1980',
              label: '1980',
            },
            {
              value: '1981',
              label: '1981',
            },
            {
              value: '1982',
              label: '1982',
            },
            {
              value: '1983',
              label: '1983',
            },
            {
              value: '1984',
              label: '1984',
            },
            {
              value: '1985',
              label: '1985',
            },
            {
              value: '1986',
              label: '1986',
            },
            {
              value: '1987',
              label: '1987',
            },
            {
              value: '1988',
              label: '1988',
            },
            {
              value: '1989',
              label: '1989',
            },
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
          ],
        },
      ],
    },
    {
      value: '66',
      label: 'Product 66',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v20r1',
              label: 'v20r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1979',
              label: '1979',
            },
            {
              value: '1980',
              label: '1980',
            },
            {
              value: '1981',
              label: '1981',
            },
            {
              value: '1982',
              label: '1982',
            },
            {
              value: '1983',
              label: '1983',
            },
            {
              value: '1984',
              label: '1984',
            },
            {
              value: '1985',
              label: '1985',
            },
            {
              value: '1986',
              label: '1986',
            },
            {
              value: '1987',
              label: '1987',
            },
            {
              value: '1988',
              label: '1988',
            },
            {
              value: '1989',
              label: '1989',
            },
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
          ],
        },
      ],
    },
    {
      value: '67',
      label: 'Product 67',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v18r1',
              label: 'v18r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1979',
              label: '1979',
            },
            {
              value: '1980',
              label: '1980',
            },
            {
              value: '1981',
              label: '1981',
            },
            {
              value: '1982',
              label: '1982',
            },
            {
              value: '1983',
              label: '1983',
            },
            {
              value: '1984',
              label: '1984',
            },
            {
              value: '1985',
              label: '1985',
            },
            {
              value: '1986',
              label: '1986',
            },
            {
              value: '1987',
              label: '1987',
            },
            {
              value: '1988',
              label: '1988',
            },
            {
              value: '1989',
              label: '1989',
            },
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
          ],
        },
      ],
    },
    {
      value: '68',
      label: 'Product 68',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface',
              label: 'surface',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'carbon_dioxide',
              label: 'carbon_dioxide',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v18r1',
              label: 'v18r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '1979',
              label: '1979',
            },
            {
              value: '1980',
              label: '1980',
            },
            {
              value: '1981',
              label: '1981',
            },
            {
              value: '1982',
              label: '1982',
            },
            {
              value: '1983',
              label: '1983',
            },
            {
              value: '1984',
              label: '1984',
            },
            {
              value: '1985',
              label: '1985',
            },
            {
              value: '1986',
              label: '1986',
            },
            {
              value: '1987',
              label: '1987',
            },
            {
              value: '1988',
              label: '1988',
            },
            {
              value: '1989',
              label: '1989',
            },
            {
              value: '1990',
              label: '1990',
            },
            {
              value: '1991',
              label: '1991',
            },
            {
              value: '1992',
              label: '1992',
            },
            {
              value: '1993',
              label: '1993',
            },
            {
              value: '1994',
              label: '1994',
            },
            {
              value: '1995',
              label: '1995',
            },
            {
              value: '1996',
              label: '1996',
            },
            {
              value: '1997',
              label: '1997',
            },
            {
              value: '1998',
              label: '1998',
            },
            {
              value: '1999',
              label: '1999',
            },
            {
              value: '2000',
              label: '2000',
            },
            {
              value: '2001',
              label: '2001',
            },
            {
              value: '2002',
              label: '2002',
            },
            {
              value: '2003',
              label: '2003',
            },
            {
              value: '2004',
              label: '2004',
            },
            {
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
          ],
        },
      ],
    },
    {
      value: '69',
      label: 'Product 69',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface_satellite',
              label: 'surface_satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v17r1',
              label: 'v17r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
          ],
        },
      ],
    },
    {
      value: '70',
      label: 'Product 70',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface_satellite',
              label: 'surface_satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'daily_mean',
              label: 'daily_mean',
            },
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'latest',
              label: 'latest',
            },
            {
              value: 'v20r1',
              label: 'v20r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '71',
      label: 'Product 71',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface_satellite',
              label: 'surface_satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'daily_mean',
              label: 'daily_mean',
            },
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v18r1',
              label: 'v18r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
          ],
        },
      ],
    },
    {
      value: '72',
      label: 'Product 72',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface_satellite',
              label: 'surface_satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'concentration',
              label: 'concentration',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'daily_mean',
              label: 'daily_mean',
            },
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v19r1',
              label: 'v19r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
          ],
        },
      ],
    },
    {
      value: '73',
      label: 'Product 73',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface_satellite',
              label: 'surface_satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'latest',
              label: 'latest',
            },
            {
              value: 'v20r1',
              label: 'v20r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '74',
      label: 'Product 74',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface_satellite',
              label: 'surface_satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v18r1',
              label: 'v18r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
          ],
        },
      ],
    },
    {
      value: '75',
      label: 'Product 75',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface_satellite',
              label: 'surface_satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'mean_column',
              label: 'mean_column',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'instantaneous',
              label: 'instantaneous',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v19r1',
              label: 'v19r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
          ],
        },
      ],
    },
    {
      value: '76',
      label: 'Product 76',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface_satellite',
              label: 'surface_satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'latest',
              label: 'latest',
            },
            {
              value: 'v20r1',
              label: 'v20r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
            {
              value: '2020',
              label: '2020',
            },
          ],
        },
      ],
    },
    {
      value: '77',
      label: 'Product 77',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface_satellite',
              label: 'surface_satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v17r1',
              label: 'v17r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
          ],
        },
      ],
    },
    {
      value: '78',
      label: 'Product 78',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface_satellite',
              label: 'surface_satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v18r1',
              label: 'v18r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
          ],
        },
      ],
    },
    {
      value: '79',
      label: 'Product 79',
      children: [
        {
          value: 'input_observations',
          label: 'Input_observations',
          children: [
            {
              value: 'surface_satellite',
              label: 'surface_satellite',
            },
          ],
        },
        {
          value: 'month',
          label: 'Month',
          children: [
            {
              value: '01',
              label: '01',
            },
            {
              value: '02',
              label: '02',
            },
            {
              value: '03',
              label: '03',
            },
            {
              value: '04',
              label: '04',
            },
            {
              value: '05',
              label: '05',
            },
            {
              value: '06',
              label: '06',
            },
            {
              value: '07',
              label: '07',
            },
            {
              value: '08',
              label: '08',
            },
            {
              value: '09',
              label: '09',
            },
            {
              value: '10',
              label: '10',
            },
            {
              value: '11',
              label: '11',
            },
            {
              value: '12',
              label: '12',
            },
          ],
        },
        {
          value: 'quantity',
          label: 'Quantity',
          children: [
            {
              value: 'surface_flux',
              label: 'surface_flux',
            },
          ],
        },
        {
          value: 'time_aggregation',
          label: 'Time_aggregation',
          children: [
            {
              value: 'monthly_mean',
              label: 'monthly_mean',
            },
          ],
        },
        {
          value: 'variable',
          label: 'Variable',
          children: [
            {
              value: 'methane',
              label: 'methane',
            },
          ],
        },
        {
          value: 'version',
          label: 'Version',
          children: [
            {
              value: 'v19r1',
              label: 'v19r1',
            },
          ],
        },
        {
          value: 'year',
          label: 'Year',
          children: [
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
            {
              value: '2011',
              label: '2011',
            },
            {
              value: '2012',
              label: '2012',
            },
            {
              value: '2013',
              label: '2013',
            },
            {
              value: '2014',
              label: '2014',
            },
            {
              value: '2015',
              label: '2015',
            },
            {
              value: '2016',
              label: '2016',
            },
            {
              value: '2017',
              label: '2017',
            },
            {
              value: '2018',
              label: '2018',
            },
            {
              value: '2019',
              label: '2019',
            },
          ],
        },
      ],
    },
  ];
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [selectedMetaInfoFields, setSelectedMetaInfoFields] = useState([]);

  useEffect(() => {
    // Automatically update selectedMetaInfoFields when selectedProducts or selectedOptions change
    updateSelectedMetaInfoFields();
  }, [selectedProducts, selectedOptions, isAdvanced]);

  const handleProductChange = (value) => {
    setSelectedProducts(value);
    setValidationErrors({});
  };

  const handleOptionChange = (value, product, category) => {
    // Update selectedOptions scoped by product and category
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [product]: {
        ...prevOptions[product],
        [category]: value,
      },
    }));

    // Clear validation errors for this specific product-category combination
    setValidationErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[product]?.[category]) {
        delete newErrors[product][category];
      }
      return newErrors;
    });
  };

  const handleSelectAll = (product, category, options) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [product]: {
        ...prevOptions[product],
        [category]: options.map((opt) => opt.value),
      },
    }));
  };

  const handleUnselectAll = (product, category) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [product]: {
        ...prevOptions[product],
        [category]: [],
      },
    }));
  };

  const renderOptions = (product, options) => {
    return options.map((option) => {
      if (option.children) {
        const productOptions = selectedOptions[product] || {};
        const hasError = validationErrors[product]?.[option.value];
        const selectedValues = productOptions[option.value] || [];

        return (
          <div key={option.value} style={{ marginBottom: '1rem' }}>
            <label style={{ fontWeight: 'bold' }}>{option.label}:</label>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
              <Select
                mode='multiple'
                style={{
                  flex: 1,
                  borderColor: hasError ? 'red' : undefined,
                }}
                placeholder={`Select ${option.label}`}
                value={selectedValues}
                onChange={(value) => handleOptionChange(value, product, option.value)}
              >
                {option.children.map((child) => (
                  <Option key={child.value} value={child.value}>
                    {child.label}
                  </Option>
                ))}
              </Select>
              <Button
                type='link'
                style={{ marginLeft: '1rem' }}
                onClick={() => handleSelectAll(product, option.value, option.children)}
              >
                Select All
              </Button>
              <Button type='link' onClick={() => handleUnselectAll(product, option.value)}>
                Unselect All
              </Button>
            </div>
            {hasError && (
              <p style={{ color: 'red', marginTop: '0.5rem' }}>Please select at least one option for {option.label}.</p>
            )}
            <div style={{ marginLeft: '1rem', marginTop: '1rem' }}>{renderOptions(product, option.children)}</div>
          </div>
        );
      }
      return null;
    });
  };
  const autoSelectAllOptions = () => {
    const newOptions = { ...selectedOptions };

    selectedProducts.forEach((product) => {
      const productData = data.find((p) => p.value === product);

      const selectAll = (options) => {
        options.forEach((option) => {
          if (option.children) {
            newOptions[option.value] = option.children.map((child) => child.value);
            selectAll(option.children); // Recursive selection
          }
        });
      };

      if (productData) {
        selectAll(productData.children);
      }
    });

    setSelectedOptions(newOptions);
  };

  const updateSelectedMetaInfoFields = () => {
    const updatedFields = [];

    if (!isAdvanced) {
      // If not in advanced mode, automatically select all options for selected products
      selectedProducts.forEach((product) => {
        const productData = data.find((p) => p.value === product);
        if (productData) {
          const addFields = (options) => {
            options.forEach((option) => {
              if (option.children) {
                option.children.forEach((child) => {
                  updatedFields.push([product, option.value, child.value]);
                });
                addFields(option.children); // Recursively add children
              }
            });
          };
          addFields(productData.children);
        }
      });
    } else {
      // If in advanced mode, add only selected options for selected products
      selectedProducts.forEach((product) => {
        const productData = data.find((p) => p.value === product);
        if (productData) {
          const addSelectedFields = (options) => {
            options.forEach((option) => {
              const selectedValues = selectedOptions[option.value] || [];
              selectedValues.forEach((value) => {
                updatedFields.push([product, option.value, value]);
              });
              if (option.children) {
                addSelectedFields(option.children); // Recursively add selected options from children
              }
            });
          };
          addSelectedFields(productData.children);
        }
      });
    }

    // Update the state of selectedMetaInfoFields
    setSelectedMetaInfoFields(updatedFields);
  };

  const handleGenerateScript = () => {
    // Add your script generation logic here
    console.log('Generating Python Script...');
  };

  return (
    <div style={{ padding: '2rem', color: 'white' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <Card style={{ flex: 1, marginRight: '1rem' }}>
          <Col span={6}>
            <label style={{ fontWeight: 'bold' }}>Select Products:</label>
            <Select
              mode='multiple'
              style={{ width: '30%', marginTop: '0.5rem' }}
              placeholder='Select products'
              value={selectedProducts}
              onChange={handleProductChange}
            >
              {data.map((product) => (
                <Option key={product.value} value={product.value}>
                  {product.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Button type={isAdvanced ? 'primary' : 'default'} onClick={() => setIsAdvanced((prev) => !prev)}>
              {isAdvanced ? 'Hide Advanced' : 'Advanced'}
            </Button>
          </Col>
          <Col span={6}>
            <Form.Item name='Dataset Download Option' style={{ width: '10%' }}>
              <Select showSearch placeholder='Select Download Option' style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={3} offset={1}>
            <Button type='primary' onClick={handleGenerateScript}>
              Generate Script
            </Button>
          </Col>
        </Card>
      </div>

      {isAdvanced && selectedProducts.length > 0 && (
        <div>
          {selectedProducts.map((product) => {
            const productData = data.find((p) => p.value === product);
            return (
              <Card key={product} title={`Selected Product: ${productData?.label}`} style={{ marginBottom: '1.5rem' }}>
                {productData && renderOptions(product, productData.children)}
              </Card>
            );
          })}
        </div>
      )}

      {/* Display the selectedMetaInfoFields for debugging purposes */}
      <div style={{ marginTop: '1rem', backgroundColor: '#333', padding: '1rem', borderRadius: '8px' }}>
        <h3>Selected Meta Info Fields:</h3>
        <pre>{JSON.stringify(selectedMetaInfoFields, null, 2)}</pre>
      </div>
    </div>
  );
}
